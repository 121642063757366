class CardInfoGloSliderGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('CTATemplateDefault before load');
		
	}

	afterLoad() {
		
	
	}

	beforeUpdate() {
		// 
	}

	afterUpdate() {
	}


}

!customElements.get('bat-card-gloitsliderinfoglo') &&
	customElements.define('bat-card-gloitsliderinfoglo', CardInfoGloSliderGloIT);
